<template>
  <v-card>
    <v-toolbar color="titleBar" dark flat dense>
      <v-toolbar-title>
        {{ $t("order new SIP account subscription") }}
        {{ step > 1 && gfoVariant ? ": " + gfoVariant.subscription : "" }}
      </v-toolbar-title>
      <v-spacer />
      <v-tooltip top v-if="transferPhoneNumber && $config.voipTransferForm">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" :href="$config.voipTransferForm" icon download>
            <v-icon>mdi-file-edit-outline</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("POA document template") }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-stepper v-model="step" alt-labels>
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1" class="text-no-wrap">
            {{ $t("subscription") }}
          </v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 2" step="2" class="text-no-wrap">
            {{ isWho ? $t("end customer account") : $t("customer account") }}
          </v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 3" step="3" class="text-no-wrap">
            {{ $t("transfer numbers") }}
            <small>{{ $t("optional") }}</small>
          </v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 4" step="4" class="text-no-wrap">
            {{ $t("options") }}
          </v-stepper-step>
          <v-divider />

          <v-stepper-step :complete="step > 5" step="5" class="text-no-wrap">
            {{ $t("delivery") }}
          </v-stepper-step>
          <v-divider />

          <v-stepper-step step="6" class="text-no-wrap">
            {{ $t("permit") }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <validation-observer ref="obs">
            <!-- ----------------------- step 1 - subscription ----------------------- -->
            <v-stepper-content step="1">
              <validation-observer
                ref="obsStep1"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card outlined color="transparent">
                  <v-card-text>
                    <v-card class="px-6" outlined>
                      <v-data-table
                        item-key="gfo_variant.id"
                        :headers="[
                          { value: 'name' },
                          { value: 'price' },
                          {
                            text: 'action',
                            align: 'end',
                            sortable: false,
                          },
                        ]"
                        :items="productCatalog"
                        :loading="loadingProductCatalog"
                        @click:row="selectProduct"
                        hide-default-footer
                        hide-default-header
                      >
                        <template v-slot:item.name="{ item }">
                          <div class="subtitle-1 my-4">
                            {{
                              item.gfo_variant
                                ? item.gfo_variant.subscription
                                : item.caption
                            }}
                          </div>
                          <div
                            v-if="item.gfo_variant && item.gfo_variant.text"
                            class="caption my-4 text-justify"
                            v-html="item.gfo_variant.text"
                          />
                          <div
                            v-if="
                              !isWho &&
                              item.gfo_variant &&
                              item.gfo_variant.terms
                            "
                            class="caption my-4 text-justify"
                            v-html="item.gfo_variant.terms"
                          />
                        </template>
                        <template v-slot:item.price="{ item }" v-if="!isWho">
                          <div class="monospace subtitle-1">
                            {{
                              item.gfo_variant &&
                              item.gfo_variant.price_rec_incl
                                ? formatMoney(
                                    item.gfo_variant.price_rec_incl,
                                    "CHF"
                                  )
                                : "-"
                            }}
                          </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                          <v-icon right>mdi-chevron-right</v-icon>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-card-text>
                </v-card>
              </validation-observer>
            </v-stepper-content>

            <!-- ----------------------- step 2 - account ----------------------- -->
            <v-stepper-content step="2">
              <validation-observer
                ref="obsStep2"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card outlined color="transparent">
                  <v-card-text>
                    <v-card
                      outlined
                      v-if="$store.getters.isSalesPartner"
                      class="mb-8"
                    >
                      <v-toolbar color="titleBar" dark flat dense>
                        <v-toolbar-title>
                          {{ $t("sales partner options") }}
                        </v-toolbar-title>
                        <v-spacer />
                        <v-dialog
                          v-model="createCustomerDialog"
                          width="80%"
                          scrollable
                        >
                          <template v-slot:activator="{ on }">
                            <v-tooltip top v-on="on">
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  @click="createCustomerDialog = true"
                                  :disabled="
                                    loadingCustomers || createCustomerDialog
                                  "
                                  :loading="loadingCustomers"
                                  icon
                                >
                                  <v-icon>mdi-account-plus-outline</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("add customer") }}</span>
                            </v-tooltip>
                          </template>
                          <create-client-customer
                            @created="
                              createCustomerDialog = false;
                              readCustomers($event);
                            "
                            @canceled="createCustomerDialog = false"
                          />
                        </v-dialog>
                      </v-toolbar>
                      <v-card-text>
                        <v-row>
                          <v-col cols="6">
                            <validation-provider
                              vid="on_behalf_of"
                              :name="$t('on behalf of')"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-autocomplete
                                v-model="onBehalfOf"
                                name="on_behalf_of"
                                :items="customers"
                                item-text="name"
                                item-value="number"
                                :label="$t('on behalf of')"
                                :hint="$t('order on behalf of your customer')"
                                clearable
                                persistent-hint
                                hide-selected
                                single-line
                              >
                                <template v-slot:selection="data">
                                  {{ data.item.name }} #{{ data.item.number }}
                                </template>
                                <template v-slot:item="data">
                                  #{{ data.item.number }} {{ data.item.name }}
                                </template>
                              </v-autocomplete>
                            </validation-provider>
                          </v-col>
                          <v-col cols="6">
                            <validation-provider
                              vid="on_behalf_order_type"
                              :name="$t('on behalf order type')"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-select
                                v-model="onBehalfOrderType"
                                :items="onBehalfOrderTypes"
                                :label="$t('on behalf order type')"
                                :hint="$t('onBehalfOrderTypeHint')"
                                clearable
                                single-line
                                persistent-hint
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          v-if="isWho"
                          vid="account_data.corporate"
                          :name="
                            isWho
                              ? $t('end customer is corporate')
                              : $t('customer is corporate')
                          "
                          v-slot="{ errors, valid, dirty, classes }"
                        >
                          <v-switch
                            v-model="accountData.corporate"
                            name="account_data.corporate"
                            :label="
                              isWho
                                ? $t('end customer is corporate')
                                : $t('customer is corporate')
                            "
                            :error-messages="errors"
                            :class="classes"
                            :clearable="!isMobile"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-card outlined>
                      <v-toolbar color="titleBar" dark flat dense>
                        <v-toolbar-title>
                          {{
                            isWho
                              ? $t("end customer account data")
                              : $t("customer account data")
                          }}
                        </v-toolbar-title>
                        <v-spacer />
                      </v-toolbar>
                      <v-card-text>
                        <v-row v-if="accountData.corporate">
                          <v-col cols="12">
                            <validation-provider
                              vid="account_data.company"
                              :name="$t('company')"
                              rules="required|max:100"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-text-field
                                v-model="accountData.company"
                                name="account_data.company"
                                :label="$t('company')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                                counter="100"
                                :clearable="!isMobile"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row v-if="!accountData.corporate">
                          <v-col cols="6">
                            <validation-provider
                              vid="account_data.first_name"
                              :name="$t('first name')"
                              :rules="
                                (accountData.corporate ? '' : 'required|') +
                                'max:100'
                              "
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-text-field
                                v-model="accountData.first_name"
                                name="account_data.first_name"
                                :label="$t('first name')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                                counter="100"
                                :clearable="!isMobile"
                              />
                            </validation-provider>
                          </v-col>
                          <v-col cols="6">
                            <validation-provider
                              vid="account_data.last_name"
                              :name="$t('last name')"
                              :rules="
                                (accountData.corporate ? '' : 'required|') +
                                'max:100'
                              "
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-text-field
                                v-model="accountData.last_name"
                                name="account_data.last_name"
                                :label="$t('last name')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                                counter="100"
                                :clearable="!isMobile"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <address-postal
                              v-model="accountData.address"
                              additional-max-length="255"
                              additional-hide
                              :zip-max-length="10"
                              zip-name="zip_code"
                              zip-rules="required"
                              :city-max-length="100"
                              city-rules="required"
                              :street-max-length="100"
                              street-rules="required"
                              :street-number-max-length="10"
                              street-number-rules="required"
                              name-prefix="account_data."
                            />
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-card class="px-6 mt-4" outlined>
                      <v-row v-if="false">
                        <v-col cols="6">
                          <validation-provider
                            vid="account_data.email"
                            :name="$t('email')"
                            rules="email:max:100"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-text-field
                              v-model="accountData.email"
                              name="account_data.email"
                              :label="$t('email')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              counter="100"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="6">
                          <validation-provider
                            vid="account_data.mobile"
                            :name="$t('mobile number')"
                            rules="phone:max:100"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-text-field
                              v-model="accountData.mobile"
                              name="account_data.mobile"
                              :label="$t('mobile number')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="classes"
                              counter="100"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" class="">
                          <language-select
                            v-model="accountData.language"
                            name="account_data.language"
                            rules=""
                            :label="$t('language')"
                            :codes="['de', 'en', 'fr', 'it', 'es', 'pt']"
                          />
                        </v-col>
                        <v-col cols="6" v-if="false">
                          <date-picker
                            v-model="accountData.date_of_birth"
                            name="account_data.date_of_birth"
                            :label="$t('date of birth')"
                            :clearable="!isMobile"
                            :allowedDates="allowedDateOfBirth"
                            :min="
                              $moment(new Date())
                                .subtract(100, 'years')
                                .format('YYYY-MM-DD')
                            "
                            :max="
                              $moment(new Date())
                                .subtract(18, 'years')
                                .format('YYYY-MM-DD')
                            "
                            active-picker="YEAR"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" v-if="false">
                          <country-select
                            v-model="accountData.nationality"
                            name="account_data.nationality"
                            :label="$t('nationality')"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 1" text>
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="step = 3"
                      :disabled="invalid || loading"
                      text
                    >
                      {{ $t("continue") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </validation-observer>
            </v-stepper-content>

            <!-- ----------------------- step 3 - transfer ----------------------- -->
            <v-stepper-content step="3">
              <validation-observer ref="obsStep3" v-slot="{ errors, invalid }">
                <v-card class="mb-12" outlined color="transparent">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <validation-provider
                          vid="transfer_phone_number"
                          :name="$t('transfer phone number')"
                          v-slot="{ errors, classes }"
                        >
                          <v-switch
                            v-model="transferPhoneNumber"
                            :label="
                              transferPhoneNumber
                                ? $t('Yes, I would like to port a number.')
                                : $t('No, I would not like to port a number.')
                            "
                            name="transfer_phone_number"
                            :error-messages="errors"
                            :class="classes"
                          />
                        </validation-provider>
                      </v-col>
                    </v-row>

                    <v-expansion-panels
                      v-model="transferDataIndex"
                      v-if="transferPhoneNumber"
                      hover
                      flat
                    >
                      <v-expansion-panel
                        v-for="(transfer, index) in transferData"
                        :key="index"
                        class="elevation-1 mb-2"
                      >
                        <v-expansion-panel-header>
                          <v-toolbar color="transparent" flat dense>
                            <v-toolbar-title>
                              {{ $t("transfer") }} {{ index + 1 }}
                            </v-toolbar-title>
                            <v-spacer />
                            <v-tooltip
                              top
                              v-if="
                                transferPhoneNumber &&
                                maxPhoneNumberCount - currentPhoneNumberCount >
                                  0
                              "
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  @click.stop.prevent="addTransfer(index + 1)"
                                  :disabled="loading"
                                  icon
                                >
                                  <v-icon>mdi-plus-circle</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("add additional porting") }}</span>
                            </v-tooltip>
                            <v-tooltip
                              top
                              v-if="
                                transferPhoneNumber && transferData.length > 1
                              "
                            >
                              <template v-slot:activator="{ on }">
                                <v-btn
                                  v-on="on"
                                  @click.stop.prevent="removeTransfer(index)"
                                  :disabled="
                                    loading || transferData.length == 1
                                  "
                                  icon
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("remove this porting") }}</span>
                            </v-tooltip>
                          </v-toolbar>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                          <v-card class="px-6 mt-4" outlined>
                            <div v-if="transfer.connection_type == 'DDI'">
                              <v-row>
                                <v-col cols="6">
                                  <validation-provider
                                    :vid="
                                      'transfer_data.' +
                                      index +
                                      '.phone_numbers_ddi_start'
                                    "
                                    :name="$t('block start number')"
                                    rules="required|phone:CH,false|phone_type:CH,FIXED_LINE+FIXED_LINE_OR_MOBILE+VOIP+UAN"
                                    v-slot="{ errors, valid, dirty, classes }"
                                  >
                                    <v-text-field
                                      v-model="transfer.phone_numbers_ddi_start"
                                      :name="
                                        'transfer_data.' +
                                        index +
                                        '.phone_numbers_ddi_start'
                                      "
                                      :label="$t('block start number')"
                                      :error-messages="errors"
                                      :success="dirty && valid"
                                      :class="classes"
                                      :clearable="!isMobile"
                                      @blur="
                                        transfer.phone_numbers_ddi_start =
                                          formatPhoneNumber(
                                            transfer.phone_numbers_ddi_start,
                                            'CH',
                                            true
                                          )
                                      "
                                      @change="
                                        transfer.phone_numbers_ddi_start =
                                          formatPhoneNumber(
                                            transfer.phone_numbers_ddi_start,
                                            'CH',
                                            true
                                          )
                                      "
                                      full-width
                                    />
                                  </validation-provider>
                                </v-col>
                                <v-col cols="6">
                                  <validation-provider
                                    :vid="
                                      'transfer_data.' +
                                      index +
                                      '.phone_numbers_ddi_end'
                                    "
                                    :name="$t('block end number')"
                                    :rules="
                                      'required|phone:CH,false|phone_type:CH,FIXED_LINE+FIXED_LINE_OR_MOBILE+VOIP+UAN' +
                                      '|fake_max:' +
                                      currentPhoneNumberCount +
                                      ',' +
                                      maxPhoneNumberCount +
                                      ',' +
                                      $t(
                                        'subscription allows max. {max} phone numbers overall',
                                        { max: maxPhoneNumberCount }
                                      )
                                    "
                                    v-slot="{ errors, valid, dirty, classes }"
                                  >
                                    <v-text-field
                                      v-model="transfer.phone_numbers_ddi_end"
                                      :name="
                                        'transfer_data.' +
                                        index +
                                        '.phone_numbers_ddi_end'
                                      "
                                      :label="$t('block end number')"
                                      :error-messages="errors"
                                      :success="dirty && valid"
                                      :class="classes"
                                      :clearable="!isMobile"
                                      @blur="
                                        transfer.phone_numbers_ddi_end =
                                          formatPhoneNumber(
                                            transfer.phone_numbers_ddi_end,
                                            'CH',
                                            true
                                          )
                                      "
                                      @change="
                                        transfer.phone_numbers_ddi_end =
                                          formatPhoneNumber(
                                            transfer.phone_numbers_ddi_end,
                                            'CH',
                                            true
                                          )
                                      "
                                      full-width
                                    />
                                  </validation-provider>
                                </v-col>
                              </v-row>
                            </div>
                            <div v-else>
                              <v-row class="mt-2">
                                <v-col cols="12">
                                  <phone-list-input
                                    v-model="transfer.phone_numbers"
                                    :name="
                                      'transfer_data.' +
                                      index +
                                      '.phone_numbers'
                                    "
                                    :label="$t('phone numbers')"
                                    :rules="
                                      transfer.connection_type == 'ISDN'
                                        ? 'required' +
                                          '|fake_max:' +
                                          currentPhoneNumberCount +
                                          ',' +
                                          maxPhoneNumberCount +
                                          ',' +
                                          $t(
                                            'subscription allows max. {max} phone numbers overall',
                                            { max: maxPhoneNumberCount }
                                          )
                                        : 'required' +
                                          '|fake_max:' +
                                          transfer.phone_numbers.split(';')
                                            .length +
                                          ',' +
                                          1 +
                                          ',' +
                                          $t(
                                            'analog connect type allows max. 1 number'
                                          )
                                    "
                                    :max-items="
                                      transfer.connection_type == 'ANALOG'
                                        ? 1
                                        : maxPhoneNumberCount -
                                          currentPhoneNumberCount
                                    "
                                    dialog-rules="required|phone:CH|phone_type:CH,FIXED_LINE+FIXED_LINE_OR_MOBILE+VOIP+UAN"
                                    country-code="CH"
                                    locale
                                    required
                                    :multiple="
                                      transfer.connection_type == 'ANALOG'
                                        ? false
                                        : true
                                    "
                                  />
                                </v-col>
                              </v-row>
                            </div>

                            <v-row>
                              <v-col cols="6">
                                <date-picker
                                  v-model="transfer.transfer_date"
                                  name="transfer_data.transfer_date"
                                  :label="$t('transfer date')"
                                  :hint="$t('transferDateHint')"
                                  :allowedDates="allowedDatesTransfer"
                                  :clearable="!isMobile"
                                />
                              </v-col>
                              <v-col cols="6">
                                <validation-provider
                                  :vid="
                                    'transfer_data.' + index + '.transfer_time'
                                  "
                                  :name="$t('transfer time')"
                                  rules="required"
                                  v-slot="{ errors, classes, dirty, valid }"
                                >
                                  <v-select
                                    v-model="transfer.transfer_time"
                                    :name="
                                      'transfer_data.' +
                                      index +
                                      '.transfer_time'
                                    "
                                    :label="$t('transfer time')"
                                    :items="transferTimeSlots"
                                    item-text="text"
                                    item-value="value"
                                    :error-messages="errors"
                                    :success="dirty && valid"
                                    :class="classes"
                                    :clearable="!isMobile"
                                  />
                                </validation-provider>
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="6">
                                <validation-provider
                                  :vid="
                                    'transfer_data.' +
                                    index +
                                    '.connection_type'
                                  "
                                  :name="$t('contract type')"
                                  rules="required"
                                  v-slot="{ errors, classes, dirty, valid }"
                                >
                                  <v-select
                                    v-model="transfer.connection_type"
                                    :name="
                                      'transfer_data.' +
                                      index +
                                      '.connection_type'
                                    "
                                    :label="$t('contract type')"
                                    :items="connectionTypes"
                                    item-text="text"
                                    item-value="value"
                                    :error-messages="errors"
                                    :success="dirty && valid"
                                    :class="classes"
                                    :clearable="!isMobile"
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="6">
                                <validation-provider
                                  :vid="
                                    'transfer_data.' +
                                    index +
                                    '.transfer_priority'
                                  "
                                  :name="$t('transfer priority')"
                                  rules="required"
                                  v-slot="{ errors, classes, dirty, valid }"
                                >
                                  <v-select
                                    v-model="transfer.transfer_priority"
                                    :name="
                                      'transfer_data.' +
                                      index +
                                      '.transfer_priority'
                                    "
                                    :label="$t('transfer priority')"
                                    :items="transferPriorities"
                                    item-text="text"
                                    item-value="value"
                                    :error-messages="errors"
                                    :success="dirty && valid"
                                    :class="classes"
                                    :clearable="!isMobile"
                                  />
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-card>

                          <v-card
                            class="px-6 mt-4"
                            outlined
                            v-if="transferPhoneNumber"
                          >
                            <v-row>
                              <v-col cols="6">
                                <validation-provider
                                  :vid="
                                    'transfer_data.' + index + '.poa_document'
                                  "
                                  :name="$t('POA document')"
                                  :rules="
                                    'required' +
                                    (poaDocument != null ? '|size:1000' : '')
                                  "
                                  v-slot="{ errors, classes, dirty, valid }"
                                >
                                  <v-file-input
                                    v-model="transfer.poaDocument"
                                    :name="
                                      'transfer_data.' + index + '.poa_document'
                                    "
                                    :label="$t('POA document')"
                                    :hint="$t('poaDocumentHint')"
                                    :error-messages="errors"
                                    :success="dirty && valid"
                                    :class="classes"
                                    accept="application/pdf"
                                    @change="convertToBase64(transfer)"
                                    show-size
                                    persistent-hint
                                  >
                                    <v-tooltip
                                      slot="append"
                                      top
                                      v-if="
                                        transferPhoneNumber &&
                                        ((isWho &&
                                          $config.mobileTransferFormWho) ||
                                          $config.mobileTransferForm)
                                      "
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-btn
                                          v-on="on"
                                          :href="
                                            isWho
                                              ? $config.mobileTransferFormWho
                                              : $config.mobileTransferForm
                                          "
                                          icon
                                          download
                                        >
                                          <v-icon>mdi-file-edit-outline</v-icon>
                                        </v-btn>
                                      </template>
                                      <span>{{
                                        $t("POA document template")
                                      }}</span>
                                    </v-tooltip>
                                  </v-file-input>
                                </validation-provider>
                              </v-col>
                              <v-col cols="6">
                                <date-picker
                                  v-model="transfer.poa_date"
                                  :name="'transfer_data.' + index + '.poa_date'"
                                  :label="$t('POA date')"
                                  rules="required"
                                  :allowedDates="allowedDatesPoa"
                                />
                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col cols="6">
                                <validation-provider
                                  :vid="
                                    'transfer_data.' + index + '.poa_signed_by'
                                  "
                                  :name="$t('POA signed by')"
                                  rules="required|max:100"
                                  v-slot="{ errors, classes, dirty, valid }"
                                >
                                  <v-text-field
                                    v-model="transfer.poa_signed_by"
                                    :name="
                                      'transfer_data.' +
                                      index +
                                      '.poa_signed_by'
                                    "
                                    :label="$t('POA signed by')"
                                    :error-messages="errors"
                                    :success="dirty && valid"
                                    :class="classes"
                                    :clearable="!isMobile"
                                    prepend-icon="mdi-signature-freehand"
                                    counter="100"
                                  />
                                </validation-provider>
                              </v-col>
                              <v-col cols="6"> </v-col>
                            </v-row>
                          </v-card>

                          <v-card
                            class="px-6 mt-4"
                            flat
                            v-if="
                              transferPhoneNumber &&
                              ($store.getters.isStaffAdmin || !isWho)
                            "
                          >
                            <v-row>
                              <v-col cols="12">
                                <validation-provider
                                  :vid="
                                    'transfer_data.' +
                                    index +
                                    '.use_specific_subscriber_address'
                                  "
                                  :name="$t('use specific subscriber address')"
                                  v-slot="{ errors, classes }"
                                >
                                  <v-switch
                                    v-model="
                                      transfer.use_specific_subscriber_address
                                    "
                                    :name="
                                      'transfer_data.' +
                                      index +
                                      '.use_specific_subscriber_address'
                                    "
                                    :label="
                                      $t('use specific subscriber address')
                                    "
                                    :error-messages="errors"
                                    :class="classes"
                                  />
                                </validation-provider>
                              </v-col>
                            </v-row>
                          </v-card>

                          <v-row
                            v-if="
                              transferPhoneNumber &&
                              transfer.use_specific_subscriber_address
                            "
                          >
                            <v-col cols="12">
                              <v-card outlined class="px-6">
                                <v-row>
                                  <v-col cols="12">
                                    <validation-provider
                                      :vid="
                                        'transfer_data.' +
                                        index +
                                        '.subscriber_address.company'
                                      "
                                      :name="$t('company')"
                                      rules="max:100"
                                      v-slot="{ errors, valid, dirty, classes }"
                                    >
                                      <v-text-field
                                        v-model="
                                          transfer.subscriber_address.company
                                        "
                                        :name="
                                          'transfer_data.' +
                                          index +
                                          '.subscriber_address.company'
                                        "
                                        :label="$t('company')"
                                        :error-messages="errors"
                                        :success="dirty && valid"
                                        :class="classes"
                                        counter="100"
                                        :clearable="!isMobile"
                                      />
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="6">
                                    <validation-provider
                                      :vid="
                                        'transfer_data.' +
                                        index +
                                        '.subscriber_address.first_name'
                                      "
                                      :name="$t('first name')"
                                      rules="max:100"
                                      v-slot="{ errors, valid, dirty, classes }"
                                    >
                                      <v-text-field
                                        v-model="
                                          transfer.subscriber_address.first_name
                                        "
                                        :name="
                                          'transfer_data.' +
                                          index +
                                          '.subscriber_address.first_name'
                                        "
                                        :label="$t('first name')"
                                        :error-messages="errors"
                                        :success="dirty && valid"
                                        :class="classes"
                                        counter="100"
                                        :clearable="!isMobile"
                                      />
                                    </validation-provider>
                                  </v-col>
                                  <v-col cols="6">
                                    <validation-provider
                                      :vid="
                                        'transfer_data.' +
                                        index +
                                        '.subscriber_address.last_name'
                                      "
                                      :name="$t('last name')"
                                      rules="max:100"
                                      v-slot="{ errors, valid, dirty, classes }"
                                    >
                                      <v-text-field
                                        v-model="
                                          transfer.subscriber_address.last_name
                                        "
                                        :name="
                                          'transfer_data.' +
                                          index +
                                          '.subscriber_address.last_name'
                                        "
                                        :label="$t('last name')"
                                        :error-messages="errors"
                                        :success="dirty && valid"
                                        :class="classes"
                                        counter="100"
                                        :clearable="!isMobile"
                                      />
                                    </validation-provider>
                                  </v-col>
                                </v-row>
                              </v-card>
                            </v-col>
                          </v-row>
                          <v-row
                            v-if="
                              transferPhoneNumber &&
                              transfer.use_specific_subscriber_address
                            "
                          >
                            <v-col cols="12">
                              <v-card outlined class="px-6">
                                <address-postal
                                  v-model="transfer.subscriber_address"
                                  additional-max-length="255"
                                  :zip-max-length="10"
                                  zip-name="zip_code"
                                  zip-rules="required"
                                  :city-max-length="100"
                                  city-rules="required"
                                  :street-max-length="100"
                                  street-rules="required"
                                  :street-number-max-length="10"
                                  street-number-rules="required"
                                  country-hide
                                  :name-prefix="
                                    'transfer_data.' +
                                    index +
                                    '.subscriber_address.'
                                  "
                                />
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <v-row class="mt-6" v-if="transferPhoneNumbers.length > 0">
                      <v-col cols="12">
                        <span class="mr-4 text-subtitle-2">
                          {{ $t("ported phone numbers") }}:
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <v-chip
                          v-for="(number, index) in transferPhoneNumbers"
                          :key="index"
                          class="ml-2 mb-2"
                          outlined
                        >
                          {{ formatPhoneNumber(number, "CH", true) }}
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row v-if="phoneNumberDuplicateError" no-glutters>
                      <v-col cols="12">
                        <v-alert text type="error">
                          {{ phoneNumberDuplicateError }}
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 2" text>
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="step = 4"
                      :disabled="
                        invalid || loading || phoneNumberDuplicateError != null
                      "
                      text
                    >
                      {{ $t("continue") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </validation-observer>
            </v-stepper-content>

            <!-- ----------------------- step 4 - options ----------------------- -->
            <v-stepper-content step="4">
              <validation-observer
                ref="obsStep4"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card outlined color="transparent">
                  <v-card-text>
                    <v-card
                      outlined
                      v-if="transferPhoneNumbers.length > 0"
                      class="mb-4"
                    >
                      <v-toolbar color="titleBar" dark flat dense>
                        <v-toolbar-title>
                          {{ $t("ported phone numbers") }}
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="px-6">
                        <v-chip
                          v-for="(number, index) in transferPhoneNumbers"
                          :key="index"
                          class="ml-2 mb-2"
                          outlined
                        >
                          {{ formatPhoneNumber(number, "CH", true) }}
                        </v-chip>
                      </v-card-text>
                    </v-card>

                    <v-card outlined>
                      <v-toolbar color="titleBar" dark flat dense>
                        <v-toolbar-title>
                          {{ $t("new numbers") }}
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="px-6">
                        <v-row no-glutters>
                          <v-col cols="12">
                            <phone-select-number
                              :base-product-id="baseProductId"
                              :label="$t('phone number')"
                              :disabled="
                                maxPhoneNumberCount - currentPhoneNumberCount ==
                                0
                              "
                              :selected-numbers="voipNumbers"
                              @select="
                                $event && $event.formated
                                  ? voipNumbers.push($event.number)
                                  : null
                              "
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-chip
                              v-for="(number, index) in voipNumbers"
                              :key="index"
                              class="ml-2 mb-2"
                              close
                              outlined
                              @click:close="removeVoipNumber(index)"
                            >
                              {{ formatPhoneNumber(number, "CH", true) }}
                            </v-chip>
                          </v-col>
                        </v-row>
                        <v-row v-if="phoneNumberErrors" no-glutters>
                          <v-col cols="12">
                            <v-alert text type="error">
                              {{ phoneNumberErrors }}
                            </v-alert>
                          </v-col>
                        </v-row>
                        <v-row no-glutters>
                          <v-col cols="12">
                            <v-alert text type="info">
                              {{
                                $t("phoneNumberStatusTxt", {
                                  availablePhoneNumberCount:
                                    maxPhoneNumberCount -
                                    currentPhoneNumberCount,
                                  maxPhoneNumberCount: maxPhoneNumberCount,
                                  minPhoneNumberCount: minPhoneNumberCount,
                                  additionalNumberCount:
                                    maxPhoneNumberCount - minPhoneNumberCount,
                                  currentPhoneNumberCount:
                                    currentPhoneNumberCount,
                                })
                              }}
                            </v-alert>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-card outlined class="mt-4">
                      <v-toolbar color="titleBar" dark flat dense>
                        <v-toolbar-title>
                          {{ $t("other options") }}
                        </v-toolbar-title>
                      </v-toolbar>
                      <v-card-text class="px-6">
                        <v-row
                          align="center"
                          justify="center"
                          v-if="isBusiness"
                        >
                          <v-col cols="4">
                            {{ $t("number of voice channels") }}
                          </v-col>
                          <v-col cols="8">
                            <validation-provider
                              vid="add_channels"
                              :name="$t('number of voice channels')"
                              :rules="
                                'required|numeric|min_value:' +
                                minChannelCount +
                                '|max_value:' +
                                maxChannelCount
                              "
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-text-field
                                v-model="voipChannels"
                                name="add_channels"
                                :label="$t('number of voice channels')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                                :clearable="!isMobile"
                                :disabled="minChannelCount == maxChannelCount"
                                type="number"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row
                          no-glutters
                          v-if="maxChannelCount - minChannelCount > 0"
                        >
                          <v-col cols="12">
                            <v-alert text type="info">
                              {{
                                $t("phoneChannelStatusTxt", {
                                  availableChannelCount:
                                    maxChannelCount - voipChannels,
                                  maxChannelCount: maxChannelCount,
                                  minChannelCount: minChannelCount,
                                  additionalChannelCount:
                                    maxChannelCount - minChannelCount,
                                  voipChannels: voipChannels,
                                })
                              }}
                            </v-alert>
                          </v-col>
                        </v-row>
                        <v-row
                          align="center"
                          justify="center"
                          v-if="isBusiness"
                        >
                          <v-col cols="4">
                            {{ $t("flat int") }}
                          </v-col>
                          <v-col cols="8">
                            <validation-provider
                              vid="flat_int"
                              :name="$t('flat int')"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-select
                                v-model="intFlat"
                                :items="intFlats"
                                :label="$t('flat int')"
                                :error-messages="errors"
                                :success="dirty && valid"
                                :class="classes"
                                :clearable="!isMobile"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>

                        <v-row align="center" justify="center">
                          <v-col cols="4">
                            {{ $t("sla") }}
                          </v-col>
                          <v-col cols="8">
                            <validation-provider
                              vid="sla"
                              :name="$t('sla')"
                              v-slot="{ errors, valid, dirty, classes }"
                            >
                              <v-switch
                                v-model="sla"
                                name="sla"
                                :error-messages="errors"
                              />
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>

                    <v-card
                      outlined
                      class="mt-6"
                      v-if="!isWho && productItem != null"
                    >
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-overline">
                                {{ $t("costs") }}
                              </th>
                              <th class="text-overline">
                                {{ $t("unique") }}
                              </th>
                              <th class="text-overline">
                                {{ $t("monthly") }}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-if="gfoVariant">
                              <td>
                                {{ gfoVariant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    gfoVariant.price_fix_incl
                                      ? "1 x " +
                                        formatMoney(
                                          gfoVariant.price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    gfoVariant.price_rec_incl
                                      ? "1 x " +
                                        formatMoney(
                                          gfoVariant.price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>

                            <tr v-if="transferPhoneNumber">
                              <td>
                                {{ gfoVariant.porting_variant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    transferPhoneNumbers.length > 0 &&
                                    gfoVariant.porting_variant.price_fix_incl
                                      ? transferPhoneNumbers.length +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.porting_variant
                                            .price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    transferPhoneNumbers.length > 0 &&
                                    gfoVariant.porting_variant.price_rec_incl
                                      ? transferPhoneNumbers.length +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.porting_variant
                                            .price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>

                            <tr
                              v-if="
                                currentPhoneNumberCount > minPhoneNumberCount
                              "
                            >
                              <td>
                                {{ gfoVariant.number_variant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    currentPhoneNumberCount -
                                      minPhoneNumberCount >
                                      0 &&
                                    gfoVariant.number_variant.price_fix_incl
                                      ? currentPhoneNumberCount -
                                        minPhoneNumberCount +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.number_variant
                                            .price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    currentPhoneNumberCount -
                                      minPhoneNumberCount >
                                      0 &&
                                    gfoVariant.number_variant.price_rec_incl
                                      ? currentPhoneNumberCount -
                                        minPhoneNumberCount +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.number_variant
                                            .price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>
                            <tr v-if="voipChannels > minChannelCount">
                              <td>
                                {{ gfoVariant.channel_variant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    voipChannels - minChannelCount > 0 &&
                                    gfoVariant.channel_variant.price_fix_incl
                                      ? voipChannels -
                                        minChannelCount +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.channel_variant
                                            .price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    voipChannels - minChannelCount > 0 &&
                                    gfoVariant.channel_variant.price_rec_incl
                                      ? voipChannels -
                                        minChannelCount +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.channel_variant
                                            .price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>

                            <tr v-if="intFlat && intFlatGfoVariant">
                              <td>
                                {{ intFlatGfoVariant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    intFlatGfoVariant.price_fix_incl
                                      ? "1 x " +
                                        formatMoney(
                                          intFlatGfoVariant.price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    intFlatGfoVariant.price_rec_incl
                                      ? "1 x " +
                                        formatMoney(
                                          intFlatGfoVariant.price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>

                            <tr v-if="sla">
                              <td>
                                {{ gfoVariant.sla_variant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    gfoVariant.sla_variant.price_fix_incl
                                      ? "1 x " +
                                        formatMoney(
                                          gfoVariant.sla_variant.price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    gfoVariant.sla_variant.price_rec_incl
                                      ? "1 x " +
                                        formatMoney(
                                          gfoVariant.sla_variant.price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 3" text>
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="step = 5"
                      :disabled="
                        invalid || loading || phoneNumberErrors != null
                      "
                      text
                    >
                      {{ $t("continue") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </validation-observer>
            </v-stepper-content>

            <!-- ----------------------- step 5 - delivery ----------------------- -->
            <v-stepper-content step="5">
              <validation-observer
                ref="obsStep5"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card outlined color="transparent">
                  <v-card-text>
                    <hardware-shop-delivery
                      v-model="deliveryAddress"
                      :validation-data="orderData"
                      validationUrl="services/phone/sip-account-create"
                      :receipt="deliveryReceipt"
                      :default-address="defaultDeliveryAddress"
                      show-delete-address
                      show-reset-address
                      show-receipt-preview
                      show-receipt-edit
                      @receiptSaved="deliveryReceipt = $event"
                    />

                    <v-card class="px-6 mt-4" outlined>
                      <v-row>
                        <v-col cols="6">
                          <date-picker
                            v-model="deliveryDate"
                            name="delivery_date"
                            :label="$t('delivery date')"
                            :clearable="!isMobile"
                            :allowedDates="allowedDatesDelivery"
                            show-today-as-asap
                            show-today-btn
                            :today-btn-text="$t('asap.')"
                          />
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12">
                          <email-list-input
                            v-model="email"
                            name="email"
                            :label="$t('email')"
                            :hint="$t('email for order confirmation')"
                            rules="max:1024"
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="$store.getters.isStaff || isWho">
                        <v-col cols="12">
                          <validation-provider
                            vid="product_description"
                            :name="$t('product description')"
                            rules="max:100"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-text-field
                              v-model="productDescription"
                              name="product_description"
                              :label="$t('product description')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="{
                                ...classes,
                                'staff--text': !isWho,
                              }"
                              counter="100"
                              :clearable="!isMobile"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row v-if="$store.getters.isStaff || isWho">
                        <v-col cols="12">
                          <validation-provider
                            vid="note"
                            :name="$t('order note')"
                            rules="max:1024"
                            v-slot="{ errors, valid, dirty, classes }"
                          >
                            <v-textarea
                              v-model="orderNote"
                              name="order_note"
                              :label="$t('order note')"
                              :error-messages="errors"
                              :success="dirty && valid"
                              :class="{
                                ...classes,
                                'staff--text': !isWho,
                              }"
                              :clearable="!isMobile"
                              maxlength="1024"
                              counter
                              rows="1"
                              auto-grow
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn @click="step = 4" text>
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="step = 6"
                      :disabled="invalid || loading"
                      text
                    >
                      {{ $t("continue") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </validation-observer>
            </v-stepper-content>

            <!-- ----------------------- step 6 - check ----------------------- -->
            <v-stepper-content step="6">
              <validation-observer
                ref="obsStep6"
                v-slot="{ errors, invalid, validated }"
              >
                <v-card class="mb-12" outlined color="transparent">
                  <v-card-text>
                    <v-card outlined>
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{ $t("your subscription") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ $t("subscription") }}:</td>
                              <td>{{ productName }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-card
                      outlined
                      class="mt-6"
                      v-if="!isWho && productItem != null"
                    >
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-overline">
                                {{ $t("costs") }}
                              </th>
                              <th class="text-overline">
                                {{ $t("unique") }}
                              </th>
                              <th class="text-overline">
                                {{ $t("monthly") }}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-if="gfoVariant">
                              <td>
                                {{ gfoVariant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    gfoVariant.price_fix_incl
                                      ? "1 x " +
                                        formatMoney(
                                          gfoVariant.price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    gfoVariant.price_rec_incl
                                      ? "1 x " +
                                        formatMoney(
                                          gfoVariant.price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>

                            <tr v-if="transferPhoneNumber">
                              <td>
                                {{ gfoVariant.porting_variant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    gfoVariant.porting_variant.price_fix_incl
                                      ? transferPhoneNumbers.length +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.porting_variant
                                            .price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    gfoVariant.porting_variant.price_rec_incl
                                      ? transferPhoneNumbers.length +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.porting_variant
                                            .price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>

                            <tr
                              v-if="
                                currentPhoneNumberCount > minPhoneNumberCount
                              "
                            >
                              <td>
                                {{ gfoVariant.number_variant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    currentPhoneNumberCount -
                                      minPhoneNumberCount >
                                      0 &&
                                    gfoVariant.number_variant.price_fix_incl
                                      ? currentPhoneNumberCount -
                                        minPhoneNumberCount +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.number_variant
                                            .price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    currentPhoneNumberCount -
                                      minPhoneNumberCount >
                                      0 &&
                                    gfoVariant.number_variant.price_rec_incl
                                      ? currentPhoneNumberCount -
                                        minPhoneNumberCount +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.number_variant
                                            .price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>
                            <tr v-if="voipChannels > minChannelCount">
                              <td>
                                {{ gfoVariant.channel_variant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    voipChannels - minChannelCount > 0 &&
                                    gfoVariant.channel_variant.price_fix_incl
                                      ? voipChannels -
                                        minChannelCount +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.channel_variant
                                            .price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    voipChannels - minChannelCount > 0 &&
                                    gfoVariant.channel_variant.price_rec_incl
                                      ? voipChannels -
                                        minChannelCount +
                                        " x " +
                                        formatMoney(
                                          gfoVariant.channel_variant
                                            .price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>

                            <tr v-if="intFlat && intFlatGfoVariant">
                              <td>
                                {{ intFlatGfoVariant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    intFlatGfoVariant.price_fix_incl
                                      ? "1 x " +
                                        formatMoney(
                                          intFlatGfoVariant.price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    intFlatGfoVariant.price_rec_incl
                                      ? "1 x " +
                                        formatMoney(
                                          intFlatGfoVariant.price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>

                            <tr v-if="sla">
                              <td>
                                {{ gfoVariant.sla_variant.subscription }}
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    gfoVariant.sla_variant.price_fix_incl
                                      ? "1 x " +
                                        formatMoney(
                                          gfoVariant.sla_variant.price_fix_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                              <td>
                                <div class="monospace text-no-wrap">
                                  {{
                                    gfoVariant.sla_variant.price_rec_incl
                                      ? "1 x " +
                                        formatMoney(
                                          gfoVariant.sla_variant.price_rec_incl,
                                          "CHF"
                                        )
                                      : ""
                                  }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-card outlined class="mt-6">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{
                                  isWho
                                    ? $t("end customer account data")
                                    : $t("customer account data")
                                }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ $t("is corporate") }}:</td>
                              <td>
                                <boolean-value
                                  :value="accountData.corporate"
                                  color
                                />
                              </td>
                            </tr>
                            <tr v-if="accountData.corporate">
                              <td>{{ $t("company") }}:</td>
                              <td>{{ accountData.company }}</td>
                            </tr>
                            <tr>
                              <td>{{ $t("name") }}:</td>
                              <td>
                                {{ accountData.first_name }}
                                {{ accountData.last_name }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t("address") }}:</td>
                              <td>
                                {{ accountData.address.additional }}
                                <br v-if="accountData.address.additional" />
                                {{ accountData.address.street }}
                                {{ accountData.address.street_number }}
                                <br />
                                {{ accountData.address.zip_code }}
                                {{ accountData.address.city }}
                                <br />
                                {{ accountData.address.country }}
                              </td>
                            </tr>
                            <tr v-if="accountData.language">
                              <td>{{ $t("language") }}:</td>
                              <td>{{ $t("lang_" + accountData.language) }}</td>
                            </tr>
                            <tr v-if="accountData.nationality">
                              <td>{{ $t("nationality") }}:</td>
                              <td>{{ accountData.nationality }}</td>
                            </tr>
                            <tr v-if="accountData.date_of_birth">
                              <td>{{ $t("date of birth") }}:</td>
                              <td>
                                {{ formatDate(accountData.date_of_birth) }}
                              </td>
                            </tr>
                            <tr v-if="accountData.email">
                              <td>{{ $t("email") }}:</td>
                              <td>{{ accountData.email }}</td>
                            </tr>
                            <tr v-if="accountData.mobile">
                              <td>{{ $t("mobile number") }}:</td>
                              <td>{{ accountData.mobile }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-card outlined class="mt-6" v-if="transferPhoneNumber">
                      <template v-for="(transfer, index) in transferData">
                        <v-simple-table dense :key="index" class="mb-4">
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th colspan="2" class="text-overline">
                                  {{
                                    $t("number transfer data") +
                                    (transferData.length > 1
                                      ? ": " + (index + 1)
                                      : "")
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{{ $t("transfer numbers") }}:</td>
                                <td v-if="transfer.connection_type == 'DDI'">
                                  {{ transfer.phone_numbers_ddi_start }} -
                                  {{ transfer.phone_numbers_ddi_end }}
                                </td>
                                <td v-else>
                                  {{
                                    transfer.phone_numbers
                                      ? transfer.phone_numbers
                                          .split(";")
                                          .join(", ")
                                      : ""
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("transfer date") }}:</td>
                                <td>
                                  {{
                                    transfer.transfer_date
                                      ? formatDate(transfer.transfer_date)
                                      : $t("asap.")
                                  }}
                                  {{
                                    transfer.transfer_time
                                      ? formatTime(transfer.transfer_time)
                                      : ""
                                  }}
                                </td>
                              </tr>
                              <tr v-if="transfer.connection_type">
                                <td>{{ $t("contract type") }}:</td>
                                <td>
                                  {{ $t(transfer.connection_type) }}
                                </td>
                              </tr>
                              <tr v-if="transfer.transfer_type">
                                <td>{{ $t("transfer type") }}:</td>
                                <td>{{ $t(transfer.transfer_type) }}</td>
                              </tr>
                              <tr v-if="transfer.transfer_priority">
                                <td>{{ $t("transfer priority") }}:</td>
                                <td>{{ $t(transfer.transfer_priority) }}</td>
                              </tr>
                              <tr v-if="poaDocument">
                                <td>{{ $t("POA document") }}:</td>
                                <td>{{ poaDocument.name }}</td>
                              </tr>
                              <tr v-if="transfer.poa_date">
                                <td>{{ $t("POA date") }}:</td>
                                <td>
                                  {{
                                    transfer.poa_date
                                      ? formatDate(transfer.poa_date)
                                      : $t("asap.")
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>{{ $t("POA signed by") }}:</td>
                                <td>{{ transfer.poa_signed_by }}</td>
                              </tr>
                              <tr
                                v-if="
                                  transfer.use_specific_subscriber_address &&
                                  transfer.subscriber_address.company
                                "
                              >
                                <td>{{ $t("subscriber company") }}:</td>
                                <td>
                                  {{ transfer.subscriber_address.company }}
                                </td>
                              </tr>
                              <tr
                                v-if="
                                  transfer.use_specific_subscriber_address &&
                                  (transfer.subscriber_address.first_name ||
                                    transfer.subscriber_address.last_name)
                                "
                              >
                                <td>{{ $t("subscriber name") }}:</td>
                                <td>
                                  {{ transfer.subscriber_address.first_name }}
                                  {{ transfer.subscriber_address.last_name }}
                                </td>
                              </tr>
                              <tr
                                v-if="transfer.use_specific_subscriber_address"
                              >
                                <td>{{ $t("subscriber address") }}:</td>
                                <td>
                                  {{ transfer.subscriber_address.additional }}
                                  <br
                                    v-if="
                                      transfer.subscriber_address.additional
                                    "
                                  />
                                  {{ transfer.subscriber_address.street }}
                                  {{
                                    transfer.subscriber_address.street_number
                                  }}
                                  <br />
                                  {{ transfer.subscriber_address.zip_code }}
                                  {{ transfer.subscriber_address.city }}
                                </td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </template>
                    </v-card>

                    <v-card outlined class="mt-6" v-if="productItem != null">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-overline" colspan="2">
                                {{ $t("options") }}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr>
                              <td>{{ $t("phone numbers") }}</td>
                              <td class="pa-1">
                                <v-chip
                                  v-for="(number, index) in phoneNumbers"
                                  :key="index"
                                  class="ml-2 mb-2"
                                  outlined
                                  dense
                                >
                                  {{ formatPhoneNumber(number, "CH", true) }}
                                </v-chip>
                              </td>
                            </tr>
                            <tr v-if="isBusiness">
                              <td>{{ $t("voice channels") }}</td>
                              <td>{{ voipChannels }}</td>
                            </tr>
                            <tr v-if="isBusiness && intFlat">
                              <td>{{ $t("flat int") }}</td>
                              <td>{{ intFlat }} {{ $t("minutes") }}</td>
                            </tr>
                            <tr v-else-if="isBusiness">
                              <td>{{ $t("flat int") }}</td>
                              <td>-</td>
                            </tr>
                            <tr>
                              <td>{{ $t("sla") }}</td>
                              <td>{{ sla ? $t("yes") : $t("no") }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-card outlined class="mt-6">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{ $t("delivery address") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-if="deliveryAddress.company">
                              <td>{{ $t("company") }}:</td>
                              <td>
                                {{ deliveryAddress.company }}
                              </td>
                            </tr>
                            <tr
                              v-if="
                                deliveryAddress.first_name ||
                                deliveryAddress.last_name
                              "
                            >
                              <td>{{ $t("name") }}:</td>
                              <td>
                                {{ deliveryAddress.first_name }}
                                {{ deliveryAddress.last_name }}
                              </td>
                            </tr>
                            <tr>
                              <td>{{ $t("address") }}:</td>
                              <td>
                                {{ deliveryAddress.additional }}
                                <br v-if="deliveryAddress.additional" />
                                {{ deliveryAddress.street }}
                                {{ deliveryAddress.street_number }}
                                <br />
                                {{ deliveryAddress.zip_code }}
                                {{ deliveryAddress.city }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-card outlined class="mt-6">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th colspan="2" class="text-overline">
                                {{ $t("additional order data") }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ $t("delivery date") }}:</td>
                              <td>
                                {{
                                  deliveryDate
                                    ? formatDate(deliveryDate)
                                    : $t("asap.")
                                }}
                              </td>
                            </tr>
                            <tr v-if="productDescription">
                              <td>{{ $t("product description") }}:</td>
                              <td>{{ productDescription }}</td>
                            </tr>
                            <tr v-if="orderNote">
                              <td>{{ $t("order note") }}:</td>
                              <td>{{ orderNote }}</td>
                            </tr>
                            <tr v-if="email">
                              <td>{{ $t("email for order confirmation") }}:</td>
                              <td>{{ email }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <v-alert
                      class="ma-6"
                      outlined
                      prominent
                      text
                      type="info"
                      v-if="!isWho"
                    >
                      <div
                        class="text-justify"
                        v-html="gfoVariant.network_terms"
                        v-if="gfoVariant && gfoVariant.network_terms"
                      />
                      <div
                        class="text-justify"
                        v-html="gfoVariant.terms"
                        v-if="gfoVariant && gfoVariant.terms"
                      />
                      <v-switch
                        v-model="termsConfirmed"
                        :label="$t('confirm subscription terms')"
                      />
                    </v-alert>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="step = 5" text :disabled="loadingOrder">
                      {{ $t("back") }}
                    </v-btn>
                    <v-spacer />
                    <v-btn
                      color="primary"
                      @click="order"
                      :loading="loadingOrder"
                      :disabled="
                        invalid || loading || (!termsConfirmed && !isWho)
                      "
                      text
                    >
                      {{ $t("order") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </validation-observer>
            </v-stepper-content>
          </validation-observer>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import {
  parseDigits,
  parsePhoneNumberFromString,
  isEqual,
} from "libphonenumber-js";
import PhoneListInput from "@/components/basics/PhoneListInput";
import AddressPostal from "@/components/basics/AddressPostal";
import AddressNames from "@/components/basics/AddressNames";
import AddressContacts from "@/components/basics/AddressContacts";
import LanguageSelect from "@/components/basics/LanguageSelect";
import CountrySelect from "@/components/basics/CountrySelect";
import DatePicker from "@/components/basics/DatePicker";
import TimePicker from "@/components/basics/TimePicker";
import BooleanValue from "@/components/basics/BooleanValue";
import formatDateTime from "@/utils/mixins/formatDateTime";
import formatDate from "@/utils/mixins/formatDate";
import formatPhoneNumber from "@/utils/mixins/formatPhoneNumber";
import formatTime from "@/utils/mixins/formatTime";
import formatMoney from "@/utils/mixins/formatMoney";
import EmailListInput from "@/components/basics/EmailListInput";
import showErrors from "@/utils/mixins/showErrors";
import isMobile from "@/utils/mixins/isMobile";
import isTeldasNoneWorkingDay from "@/utils/mixins/teldasNoneWorkingDays";
import downloadFile from "@/utils/mixins/downloadFile";
import PhoneSelectNumber from "./PhoneSelectNumber";
import CreateClientCustomer from "@/components/reseller/CreateClientCustomer";
import HardwareShopDelivery from "@/components/orders/hardware/HardwareShopDelivery";

var emptyTransfer = {
  phone_numbers: "",
  phone_numbers_ddi_start: "",
  phone_numbers_ddi_end: "",
  handle_transfer_manually: false,
  use_specific_subscriber_address: store.getters.isWho ? true : false,
  subscriber_address: {
    company: null,
    first_name: null,
    last_name: null,
    additional: null,
    street: null,
    street_number: null,
    zip_code: null,
    city: null,
    country: null,
  },
  transfer_date: null,
  transfer_time: "10:00:00",
  poa_date: null,
  poa_signed_by: null,
  connection_type: "ANALOG",
  transfer_type: "FULL",
  transfer_priority: "NORMAL",
  add_temporary_number: false,
};

export default {
  name: "SipAccountCreate",
  components: {
    PhoneListInput,
    DatePicker,
    TimePicker,
    BooleanValue,
    EmailListInput,
    AddressPostal,
    AddressNames,
    AddressContacts,
    LanguageSelect,
    CountrySelect,
    PhoneSelectNumber,
    CreateClientCustomer,
    HardwareShopDelivery,
  },
  mixins: [
    showErrors,
    formatDateTime,
    formatDate,
    formatTime,
    formatMoney,
    formatPhoneNumber,
    isMobile,
    downloadFile,
    isTeldasNoneWorkingDay,
  ],
  data() {
    return {
      step: 1,
      loadingProductCatalog: false,
      productCatalog: [],
      gfoVariantId: null,
      baseProductId: null,
      deliveryDate: null,
      deliveryReceipt: "",
      deliveryAddress: {},
      defaultDeliveryAddress: {},
      email: this.$store.getters.deliveryEmail,
      orderNote: "",
      productDescription: "",
      voipNumbers: [],
      transferPhoneNumber: false,
      transferDataIndex: 0,
      transferData: [{ ...emptyTransfer }],
      accountData: {
        salutation: null,
        company: null,
        first_name: null,
        last_name: null,
        corporate: null,
        language: "de",
        nationality: null,
        date_of_birth: null,
        email: null,
        mobile: null,
        address: {
          additional: null,
          street: null,
          street_number: null,
          zip_code: null,
          city: null,
          country: "CH",
        },
      },
      intFlat: null,
      voipChannels: null,
      sla: false,
      poaDocument: null,
      poaDocumentBase64: null,
      overwriteDeliveryAddress: false,
      providers: [],
      loadingProviders: false,
      loadingOrder: false,
      termsConfirmed: false,
      onBehalfOf: null,
      onBehalfOrderType: "CUS",
      customers: [],
      loadingCustomers: false,
      createCustomerDialog: false,
      loadingCustomer: false,
    };
  },
  computed: {
    isWho() {
      return this.$store.getters.isWho;
    },
    isCorporate() {
      return this.isWho
        ? this.accountData.corporate
        : this.$store.getters.isCorporate;
    },
    loading() {
      return (
        this.loadingProductCatalog ||
        this.loadingProviders ||
        this.loadingOrder ||
        this.loadingValidate ||
        this.loadingCustomers ||
        this.loadingCustomer ||
        this.loadingAreaCodes
      );
    },
    onBehalfOrderTypes() {
      return [
        {
          value: "CUS",
          text: this.$i18n.t("order is placed by your customer"),
        },
        {
          value: "PAR",
          text: this.$i18n.t("order happens through you"),
        },
      ];
    },
    mfaVerificationTypes() {
      return [
        { text: this.$i18n.t("EMAIL"), value: "EMAIL" },
        // do not provide SMS !!!
        // { text: this.$i18n.t("SMS"), value: "SMS" },
      ];
    },
    connectionTypes() {
      return [
        { text: this.$i18n.t("DDI"), value: "DDI" },
        { text: this.$i18n.t("ISDN"), value: "ISDN" },
        { text: this.$i18n.t("ANALOG"), value: "ANALOG" },
      ];
    },
    transferTypes() {
      return [
        { text: this.$i18n.t("FULL"), value: "FULL" },
        {
          text: this.$i18n.t("PARTIAL"),
          value: "PARTIAL",
          disabled: true,
        },
      ];
    },
    transferPriorities() {
      return [
        { text: this.$i18n.t("NORMAL"), value: "NORMAL" },
        { text: this.$i18n.t("FAST"), value: "FAST" },
      ];
    },
    transferTimeSlots() {
      return [
        { text: "08:00 - 09:00", value: "08:00:00" },
        { text: "09:00 - 10:00", value: "09:00:00" },
        { text: "10:00 - 11:00", value: "10:00:00" },
        { text: "11:00 - 12:00", value: "11:00:00" },
        { text: "13:00 - 14:00", value: "13:00:00" },
        { text: "14:00 - 15:00", value: "14:00:00" },
        { text: "15:00 - 16:00", value: "15:00:00" },
        { text: "16:00 - 17:00", value: "16:00:00" },
      ];
    },
    intFlats() {
      return [
        { text: this.$i18n.t("not selected"), value: null },
        { text: this.$i18n.t("100 min"), value: 100 },
        { text: this.$i18n.t("300 min"), value: 300 },
        { text: this.$i18n.t("500 min"), value: 500 },
        { text: this.$i18n.t("1000 min"), value: 1000 },
      ];
    },
    productItem() {
      var that = this;
      this.productCatalog;
      this.gfoVariantId;
      var productItem = null;
      this.productCatalog.forEach(function (item) {
        if (that.gfoVariantId && item.gfo_variant.id == that.gfoVariantId) {
          productItem = item;
        }
      });
      return productItem;
    },
    gfoVariant() {
      return this.productItem && this.productItem.gfo_variant
        ? this.productItem.gfo_variant
        : null;
    },
    productName() {
      var name = "";
      var item = this.productItem;
      if (item)
        name = item.gfo_variant ? item.gfo_variant.subscription : item.caption;
      return name;
    },
    intFlatGfoVariant() {
      const name = "flat_int_" + (this.intFlat || 0) + "_variant";
      return this.gfoVariant && this.gfoVariant[name]
        ? this.gfoVariant[name]
        : null;
    },
    isBusiness() {
      return this.gfoVariant ? this.gfoVariant.is_business : false;
    },
    hasChFixFlat() {
      return this.gfoVariant && this.gfoVariant.tags
        ? this.gfoVariant.tags.includes("flatrate_fix")
        : false;
    },
    hasChMobileFlat() {
      return this.gfoVariant && this.gfoVariant.tags
        ? this.gfoVariant.tags.includes("flatrate_mobile")
        : false;
    },
    transferDates() {
      return this.transferData
        .map((transfer) => transfer.transfer_date)
        .filter(
          (transfer_date) => transfer_date != null && transfer_date != ""
        );
    },
    sortedTransferDates() {
      return this.transferDates.sort((a, b) => {
        if (this.$moment(a).isBefore(b)) return -1;
        else if (this.$moment(a).isAfter(b)) return 1;
        else return 0;
      });
    },
    earliestTransferDate() {
      return this.sortedTransferDates.length > 0
        ? this.sortedTransferDates[0]
        : null;
    },
    latestTransferDate() {
      return this.sortedTransferDates.length > 0
        ? this.sortedTransferDates[this.sortedTransferDates.length - 1]
        : null;
    },
    transferPhoneNumbers() {
      var that = this;
      var numbers = [];
      if (this.transferPhoneNumber)
        this.transferData.forEach(function (transfer) {
          numbers = numbers.concat(that.getTransferNumbersByTransfer(transfer));
        });

      // remove duplicates
      return [...new Set(numbers)];
    },
    phoneNumbers() {
      return [...new Set([...this.transferPhoneNumbers, ...this.voipNumbers])];
    },
    minPhoneNumberCount() {
      return this.gfoVariant ? this.gfoVariant.numbers_incl : 0;
    },
    maxPhoneNumberCount() {
      return this.gfoVariant ? this.gfoVariant.numbers_max : 0;
    },
    currentPhoneNumberCount() {
      return this.phoneNumbers.length;
    },
    minChannelCount() {
      return this.gfoVariant ? this.gfoVariant.channels_incl : 0;
    },
    maxChannelCount() {
      return this.gfoVariant ? this.gfoVariant.channels_max : 0;
    },
    phoneNumberDuplicateError() {
      const uniquephoneNumbers = new Set(this.phoneNumbers);

      if (uniquephoneNumbers.length < this.phoneNumbers.length)
        return this.$i18n.t("found {num} duplicated phone number(s)", {
          num: this.phoneNumbers.length - uniquephoneNumbers.length,
        });
    },
    phoneNumberErrors() {
      const availablePhoneNumberCount =
        this.maxPhoneNumberCount - this.currentPhoneNumberCount;

      if (this.currentPhoneNumberCount < this.minPhoneNumberCount)
        return this.$i18n.t(
          "min. {minPhoneNumberCount} phone number(s) required",
          { minPhoneNumberCount: this.minPhoneNumberCount }
        );
      else if (this.currentPhoneNumberCount > this.maxPhoneNumberCount)
        return this.$i18n.t(
          "max. {maxPhoneNumberCount} phone number(s) possible",
          { maxPhoneNumberCount: this.maxPhoneNumberCount }
        );
    },
    orderTransferData() {
      var that = this;
      return this.transferData.map(function (transfer) {
        return {
          phone_numbers: that.getTransferNumbersByTransfer(transfer),
          handle_transfer_manually: transfer.handle_transfer_manually,
          use_specific_subscriber_address:
            transfer.use_specific_subscriber_address,
          subscriber_address: transfer.use_specific_subscriber_address
            ? {
                company: transfer.subscriber_address.company || null,
                first_name: transfer.subscriber_address.first_name || null,
                last_name: transfer.subscriber_address.last_name || null,
                additional: transfer.subscriber_address.additional || null,
                street: transfer.subscriber_address.street || null,
                street_number:
                  transfer.subscriber_address.street_number || null,
                zip_code: transfer.subscriber_address.zip_code || null,
                city: transfer.subscriber_address.city || null,
              }
            : null,
          transfer_date: transfer.transfer_date || null,
          transfer_time: transfer.transfer_time || null,
          poa_date: transfer.poa_date,
          poa_document: transfer.poaDocumentBase64,
          poa_signed_by: transfer.poa_signed_by || null,
          connection_type: transfer.connection_type,
          transfer_type: transfer.transfer_type,
          transfer_priority: transfer.transfer_priority,
          add_temporary_number: transfer.add_temporary_number,
        };
      });
    },
    orderData() {
      return {
        on_behalf_of: this.onBehalfOf || null,
        on_behalf_order_type: this.onBehalfOrderType || null,
        psuite_product_id: this.productItem ? this.productItem.id : null,
        gfo_variant_id: this.gfoVariant ? this.gfoVariant.id : null,
        gfo_network_id: this.gfoVariant ? this.gfoVariant.network_id : null,
        delivery_date: this.deliveryDate || null,
        email: this.email || null,
        product_description: this.productDescription || null,
        note: this.orderNote || null,
        is_business: this.isBusiness,
        flat_ch_fix: this.hasChFixFlat,
        flat_ch_mobile: this.hasChMobileFlat,
        flat_int: this.intFlat,
        sla: this.sla,
        add_channels: this.voipChannels - this.minChannelCount,
        voip_numbers: this.voipNumbers,
        transfer_phone_number: this.transferPhoneNumber,
        transfer_data: this.transferPhoneNumber ? this.orderTransferData : [],
        account_data: this.accountData
          ? {
              company: this.accountData.company || null,
              first_name: this.accountData.first_name || null,
              last_name: this.accountData.last_name || null,
              additional: this.accountData.address.additional || null,
              street: this.accountData.address.street || null,
              street_number: this.accountData.address.street_number || null,
              zip_code: this.accountData.address.zip_code || null,
              city: this.accountData.address.city || null,
              country: this.accountData.address.country || null,
              corporate: this.accountData.corporate,
              language: this.accountData.language || null,
              nationality: this.accountData.nationality || null,
              date_of_birth: this.accountData.date_of_birth || null,
              email: this.accountData.email || null,
              mobile: this.accountData.mobile || null,
            }
          : null,
        delivery_address: this.deliveryAddress,
        delivery_receipt: this.deliveryReceipt,
      };
    },
  },
  watch: {
    step(value) {
      // transfer check
      if (value == 3) {
        if (
          this.deliveryDate &&
          this.transferData[this.transferDataIndex] &&
          !this.transferData[this.transferDataIndex].transfer_date
        )
          this.transferData[this.transferDataIndex].transfer_date =
            this.$moment(this.addWorkingDays(this.deliveryDate, 5)).format(
              "YYYY-MM-DD"
            );
        // options
      } else if (value == 4) {
        if (this.voipChannels == null) this.voipChannels = this.minChannelCount;
        // delivery
      } else if (value == 5) {
        if (!this.deliveryDate)
          if (this.earliestTransferDate)
            this.deliveryDate = this.$moment(
              this.subtractWorkingDays(this.earliestTransferDate, 5)
            ).format("YYYY-MM-DD");
          else
            this.deliveryDate = this.$moment(new Date()).format("YYYY-MM-DD");
      }
    },
    onBehalfOf: {
      immediate: true,
      handler(value) {
        if (value == null) {
          this.onBehalfOf = this.$store.getters.customerNumber;
          this.setCustomer(this.$store.getters.customerId);
        } else {
          for (let i = 0; i < this.customers.length; i++) {
            if (this.customers[i].number == value) {
              this.setCustomer(this.customers[i].id);
              break;
            }
          }
        }
      },
    },
  },
  methods: {
    convertToBase64(transfer) {
      if (transfer.poaDocument != null) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", function (event) {
          transfer.poaDocumentBase64 = event.target.result;
        });
        fileReader.readAsDataURL(transfer.poaDocument);
      }
    },
    getTransferNumbersByTransfer(transfer) {
      var that = this;
      var numbers = [];
      if (transfer.connection_type == "DDI") {
        if (transfer.phone_numbers_ddi_start && transfer.phone_numbers_ddi_end)
          numbers = that.getPhoneNumberRange(
            transfer.phone_numbers_ddi_start,
            transfer.phone_numbers_ddi_end,
            "CH",
            true,
            true
          );
      } else if (transfer.phone_numbers)
        numbers = transfer.phone_numbers
          .split(";")
          .map((number) => that.formatPhoneNumber(number, "CH", true, true));

      return numbers;
    },
    addTransfer(index) {
      this.transferData = [
        ...this.transferData.slice(0, index),
        { ...emptyTransfer },
        ...this.transferData.slice(index),
      ];
      this.transferDataIndex = index;
    },
    removeTransfer(index) {
      this.transferData.splice(index, 1);
      this.transferData = [...this.transferData];
      if (this.transferDataIndex >= this.transferData.length)
        this.transferDataIndex = this.transferData.length - 1;
      if (this.$refs.obsStep3) this.$refs.obsStep3.validate();
    },
    removeVoipNumber(index) {
      this.voipNumbers.splice(index, 1);
      this.voipNumbers = [...this.voipNumbers];
    },
    getPhoneNumberRange(
      phoneNumber1,
      phoneNumber2,
      countryCode,
      local,
      removeSpaces,
      maxNumbers
    ) {
      try {
        countryCode = countryCode || "CH";
        local = local || false;
        removeSpaces = removeSpaces || false;
        maxNumbers = maxNumbers || 10;

        const num1 = Number(
          parseDigits(
            parsePhoneNumberFromString(
              phoneNumber1,
              countryCode
            ).formatNational()
          )
        );
        const num2 = Number(
          parseDigits(
            parsePhoneNumberFromString(
              phoneNumber2,
              countryCode
            ).formatNational()
          )
        );
        var start = num1 < num2 ? num1 : num2;
        var end = num1 <= num2 ? num2 : num1;

        if (end - start > maxNumbers) {
          console.error(
            "range",
            end - start,
            "is greater than",
            maxNumbers,
            ": set end to",
            start + maxNumbers
          );
          end = start;
        }

        return Array.from({ length: end - start + 1 }, (_, i) => start + i).map(
          function (num) {
            var phoneNum = parsePhoneNumberFromString(String(num), countryCode);
            if (local) phoneNum = phoneNum.formatNational();
            if (removeSpaces) phoneNum = phoneNum.replace(/\s/g, "");
            return phoneNum;
          }
        );
      } catch (err) {
        console.error(err);
        return [];
      }
    },
    setAccountData(customer) {
      this.accountData = {
        salutation: customer.address.salutation || null,
        company: customer.address.company || null,
        first_name: customer.address.first_name || null,
        last_name: customer.address.last_name || null,
        corporate: customer.is_corporate,
        language: (customer.language || "DE").toLowerCase(),
        nationality: (customer.address.country || "CH").toLowerCase(),
        date_of_birth: customer.address.birthdate || null,
        email: customer.address.email || null,
        mobile: customer.address.mobile || null,
        address: {
          additional: customer.address.additional || null,
          street: customer.address.street || null,
          street_number: customer.address.street_number || null,
          zip_code: customer.address.zip_code || null,
          city: customer.address.city || null,
          country: customer.address.country || "CH",
        },
      };
    },
    getTomorrowString() {
      return this.$moment(new Date()).add(1, "days").format("YYYY-MM-DD");
    },
    getTodayString() {
      return this.$moment(new Date()).format("YYYY-MM-DD");
    },
    selectProduct(item, row) {
      this.gfoVariantId = item.gfo_variant.id;
      this.baseProductId = item.base_product_id;
      this.step = 2;
    },
    allowedDatesDelivery(date) {
      let today = this.$moment(new Date());
      let latestDate =
        this.earliestTransferDate != null
          ? this.subtractWorkingDays(this.earliestTransferDate, 5)
          : this.$moment(new Date()).add(12, "months").add(1, "days").toDate();
      return (
        this.$moment(date).isSameOrAfter(today, "day") &&
        this.$moment(date).isBefore(latestDate, "day")
      );
    },
    allowedDatesTransfer(date) {
      let latestDate = this.$moment(new Date()).add(12, "months");
      let earliestDate =
        this.deliveryDate != null
          ? this.addWorkingDays(this.deliveryDate, 5)
          : this.addWorkingDays(null, 5);
      return (
        this.$moment(date).isAfter(earliestDate, "day") &&
        this.$moment(date).isSameOrBefore(latestDate, "day") &&
        !this.isTeldasNoneWorkingDay(date)
      );
    },
    allowedDateOfBirth(date) {
      let eighteenYearsAgo = this.$moment(new Date()).subtract(18, "years");
      return (
        this.$moment(date).isBefore(eighteenYearsAgo) ||
        this.$moment(date).isSame(eighteenYearsAgo, "day")
      );
    },
    allowedDatesPoa(date) {
      let now = this.$moment(new Date());
      return (
        this.$moment(date).isBefore(now) ||
        this.$moment(date).isSame(now, "day")
      );
    },
    getProductCatalog() {
      var that = this;
      this.loadingProductCatalog = true;
      this.$http
        .get("services/phone/product-catalog", { params: { base: "voip" } })
        .then((response) => {
          that.productCatalog = response.data;
        })
        .catch((err) => {
          that.$store.commit("setSystemError", {
            msg: err.message,
            title: err.title,
          });
        })
        .finally(function () {
          that.loadingProductCatalog = false;
        });
    },
    order() {
      var that = this;
      this.loadingOrder = true;
      this.$http
        .post("services/phone/sip-account-create", this.orderData)
        .then((response) => {
          that.$snotify.success(that.$i18n.t("notifyMsg"));
          if (this.onBehalfOrderType == "PAR")
            that.$router.push({
              name: "telephony-service-sip-account-create",
            });
          else
            that.$router.push({
              name: "tasks",
              params: { selectedTaskId: response.data.id },
            });
        })
        .catch((error) => {
          //console.log(">>> error ", error.data);
          /** this will not work, as long erros will be displayed in hidden steps :-(
           *
          that.$refs.obs.setErrors(error.data);
          that.showErrors(that.$refs.obs.getUnresolvedErrors(error));
          */
          // show ALL errors as popup
          that.showErrors(error.data);
        })
        .finally(function () {
          that.loadingOrder = false;
        });
    },
    readCustomers(event) {
      var that = this;
      this.loadingCustomers = true;
      this.$http
        .get("customers", {
          params: { q: this.search, limit: 0 },
        })
        .then((response) => {
          that.customers = [
            {
              id: this.$store.getters.customerId,
              name: this.$i18n.t("for my self"),
              number: this.$store.getters.customerNumber,
            },
            ...response.data,
          ];
          that.onBehalfOf =
            event != null && event.number != null ? event.number : null;
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingCustomers = false;
        });
    },
    setCustomer(customerId) {
      var that = this;
      this.loadingCustomer = true;
      this.$http
        .get("customers/" + customerId)
        .then((response) => {
          that.setAccountData(response.data);
          that.deliveryAddress = response.data.address;
          that.defaultDeliveryAddress = { ...that.deliveryAddress };
        })
        .catch((error) => {
          that.$store.commit("setSystemError", {
            msg: error.message,
            title: error.title,
          });
        })
        .finally(function () {
          that.loadingCustomer = false;
        });
    },
  },
  mounted() {
    this.getProductCatalog();
    if (this.$store.getters.isSalesPartner) this.readCustomers();
  },
};
</script>

<i18n>
{
  "en": {
    "order new SIP account subscription": "Order new SIP account subscription",
    "account data": "Account data",
    "costs": "Costs",
    "monthly": "monthly",
    "unique": "unique",
    "options": "Options",
   
    "end customer account": "End customer account",
    "customer account": "Customer account",
    "end customer is corporate": "End customer is corporate",
    "customer is corporate": "Customer is corporate",
    "company": "Company",
    "language": "Language",
   
    "continue": "Continue",
    "back": "Back",
    "first name": "First name",
    "last name": "Last name",
    "transfer numbers": "Transfer numbers",
    "permit": "Permit",
    "continue": "Continue",
    "back": "Back",
    "order": "Order",
    "subscription": "Subscription",
    "delivery date": "Delivery date",
    "email": "Email",
    "email for order confirmation": "Notify email address",
    "product description": "Product description",
    "order note": "Own note for order confirmation",
    "Yes, I would like to port a number.": "Yes, I would like to port a number.",
    "No, I would not like to port a number.": "No, I would not like to port a number.",
    "phone numbers": "Phone numbers",
    "phone number": "Phone number",

    "multi factor authentication type": "Multi factor authentication type",
    "authentication email address": "Authentication email address",
    "authentication SMS number": "Authentication SMS number",
    "authentication target": "Authentication target",
    "mfaTypeHint": "Multi factor authentication type",
    "date of birth": "Date of birth",
    "EMAIL": "Email",
    "SMS": "SMS",
    "PASSPORT": "Passport",
    "IDCARD": "ID Card",
    "OTHER": "Other",
    "nationality": "Nationality",

    "handle transfer manually": "handle transfer manually",
    "use specific subscriber address": "use specific subscriber address",
    "transfer date": "Transfer date",
    "transfer time": "Transfer time",
    "transferDateHint": "leave empty to tranfer asap.",
    "transfer": "Transfer",

    "main number": "Main number",
    "block start number": "Block start number",
    "block end number": "Block end number",

    "add additional porting": "add additional porting",
    "remove this porting": "remove this porting",
    "subscription allows max. {max} phone numbers overall": "subscription allows max. {max} phone numbers overall",

    "phoneNumberStatusTxt": "{availablePhoneNumberCount} phone number can still be added. This subscription includes {minPhoneNumberCount} phone number(s). In addition, a maximum of {additionalNumberCount} telephone numbers can be added.",
    "min. {minPhoneNumberCount} phone number(s) required": "min. {minPhoneNumberCount} phone number(s) required",
    "max. {maxPhoneNumberCount} phone number(s) possible": "max. {maxPhoneNumberCount} phone number(s) possible",
    "found {num} duplicated phone number(s)": "found {num} duplicated phone number(s)",
    "phoneChannelStatusTxt": "{availableChannelCount} channel(s) can still be added. This subscription includes {minChannelCount} channel(s). In addition, a maximum of {additionalChannelCount} channels can be added.",

    "other options": "Other options",
    "flat int": "EU flatrate",
    "not selected": "not selected",
    "100 min": "100 minutes",
    "300 min": "300 minutes",
    "500 min": "500 minutes",
    "1000 min": "1000 minutes",
    "number of voice channels": "Number of voice channels",
    "sla": "SLA KMU Voice",

    "voice channels": "Voice channels",

    "POA document": "POA document",
    "poaDocumentHint": "completed and signed power of attorney form (see template link above)",
    "POA date": "POA date",
    "POA signed by": "POA signed by",
    "transfer phone number": "Transfer phone number",
    "contract type": "Contract type",
    "DDI": "DDI",
    "ISDN": "ISDN (port multiple numbers)",
    "ANALOG": "Analog (port individual number)",
    "transfer type": "Transfer type",
    "FULL": "Full transfer",
    "PARTIAL": "Partial transfer",
    "transfer priority": "Transfer priority",
    "NORMAL": "Take into account the duration of the contract",
    "FAST": "Do not take into account the duration of the contract",
    "subscriber company": "Subscriber company",
    "subscriber name": "Subscriber name",
    "subscriber address": "Subscriber address",
    "your subscription": "Your subscription",
    "user identify data": "User identify data",
    "number transfer data": "Number transfer data",
    "asap.": "asap.",
    "notifyMsg": "Your order has been successfully committed.",
    "POA document template": "POA document template",
    "end customer account data": "End customer account data",
    "customer account data": "Customer account data",
    "is corporate": "is corporate",
    "address": "Address",
    "lang_de": "German",
    "lang_en": "English",
    "lang_fr": "French",
    "lang_it": "Italian",
    "lang_es": "Spanish",
    "lang_pt": "Portuguese",
    "sales partner options": "Sales partner options",
    "for my self": "for my self",
    "on behalf of": "On behalf of",
    "order on behalf of your customer": "order on behalf of your customer",
    "on behalf order type": "Order type",
    "onBehalfOrderTypeHint": "The product is always created for your customer with you as a reference. For the ordering process, you can choose in whose name the order and communication will be carried out.",
    "order happens through you": "order happens through you",
    "order is placed by your customer": "order is placed by your customer",
    "add customer": "add own customer",
    "change delivery address": "Change delivery address",
    "preview delivery receipt": "Preview delivery receipt",
    "edit delivery receipt": "Edit delivery receipt",
    "delivery": "Delivery",
    "delivery address": "Delivery address",
    "delete delivery address": "Delete delivery address",
    "set deault delivery address": "Set default delivery address",
    "delivery receipt language": "Delivery receipt language",
    "delivery-receipt.pdf": "delivery-receipt.pdf",
    "additional order data": "Additional order data",
    "subscription price": "Subscription price",
    "subscription setup fee": "Subscription setup fee",
    "confirm subscription terms": "confirm subscription terms",
    "new numbers": "New numbers",
    "ported phone numbers": "Ported phone numbers",
    "minutes": "minutes",
    "yes": "yes",
    "no": "no",
    "analog connect type allows max. 1 number": "analog connect type allows max. 1 number"
  },
  "de": {
    "order new SIP account subscription": "neuen SIP Account bestellen",
    "account data": "Kundenkonto Daten",
    "costs": "Kosten",
    "monthly": "monatlich",
    "unique": "einmalig",
    "options": "Optionen",

    "end customer account": "Endkundenkonto",
    "customer account": "Kundenkonto",
    "end customer is corporate": "Endkunde ist eine Firma",
    "customer is corporate": "Kunde ist eine Firma",
    "company": "Firma",
    "mobile number": "Mobil Nummer",
    "language": "Sprache",
   
    "continue": "Weiter",
    "back": "Zurück",
    "order new mobile phone subscription": "Neues Mobiltelefon Abonnement bestellen",
    "first name": "Vorname",
    "last name": "Nachname",
    "identity check": "Identitätsprüfung",
    "transfer numbers": "Rufnummern portieren",
    "permit": "Bestätigen",
    "optional": "Optional",
    "continue": "Weiter",
    "back": "Zurück",
    "order": "Bestellen",
    "subscription": "Abonnement",
    "delivery date": "Aufschaltdatum",
    "email": "Email",
    "email for order confirmation": "Email für Bestellbestätigung",
    "product description": "Produktbeschreibung",
    "order note": "Eigene Notiz für Bestellbestätigung",
    "Yes, I would like to port a number.": "Ja, ich möchte eine Rufnummer portieren.",
    "No, I would not like to port a number.": "Nein, ich möchte keine Rufnummer portieren.",
    "phone numbers": "Rufnummern",
    "phone number": "Rufnummer",

    "multi factor authentication type": "Multi Faktor Authentisierungstyp",
    "authentication email address": "Authentisierungs Email Adresse",
    "authentication SMS number": "Authentisierungs SMS Nummer",
    "authentication target": "Authentisierungs Ziel",
    "mfaTypeHint": "Multi Faktor Authentisierungstyp",
    "date of birth": "Geburtsdatum",
    "EMAIL": "Email",
    "SMS": "SMS",
    "PASSPORT": "Reisepass",
    "IDCARD": "Identitätskarte",
    "OTHER": "Andere",
    "nationality": "Nationalität",
    
    "handle transfer manually": "bearbeite Transfer manuell",
    "use specific subscriber address": "benutze abweichende Eigentümer Adresse",
    "transfer date": "Portierung Datum",
    "transfer time": "Portierung Zeit",
    "transferDateHint": "leer lassen für schnellstmögliche Portierung",
    "transfer": "Portierung",

    "main number": "Main number",
    "block start number": "Block start number",
    "block end number": "Block end number",

    "add additional porting": "weitere Portierung hinzufügen",
    "remove this porting": "diese Portierung löschen",
    "subscription allows max. {max} phone numbers overall": "Abonnement beinhaltet insgesamt max. {max} Telefonnummern",

    "phoneNumberStatusTxt": "{availablePhoneNumberCount} Telefonnummern können noch hinzugefügt werden. Diese Abo enthält {minPhoneNumberCount} Telefonnummer(n). Zusätzlich, können {additionalNumberCount} Telefonnummern hinzugefügt werden.",
    "min. {minPhoneNumberCount} phone number(s) required": "min. {minPhoneNumberCount} Telefonnummer(n) erforderlich",
    "max. {maxPhoneNumberCount} phone number(s) possible": "max. {maxPhoneNumberCount} Telefonnummer(n) möglich",
    "found {num} duplicated phone number(s)": "{num} doppelte Telefonnummer(n) gefunden",
    "phoneChannelStatusTxt": "{availableChannelCount} Sprachkanäle können noch hinzugefügt werden. Dieses Abo enthält {minChannelCount} Sprachkanäle. Zusätzlich, können {additionalChannelCount} Sprachkanäle hinzugefügt werden.",

    "other options": "Weitere Optionen",
    "flat int": "EU Flatrate",
    "not selected": "nicht ausgewählt",
    "100 min": "100 Minuten",
    "300 min": "300 Minuten",
    "500 min": "500 Minuten",
    "1000 min": "1000 Minuten",
    "number of voice channels": "Anzahl der Sprachkanäle",
    "sla": "SLA KMU Voice",

    "voice channels": "Sprachkanäle",

    "POA document": "Vollmachtsformular",
    "poaDocumentHint": "ausgefülltes und unterzeichnetes Vollmachtsformular (siehe Vorlagen Link oben)",
    "POA date": "Datum der Vollmacht",
    "POA signed by": "Vollmacht Unterschrieben von",
    "transfer phone number": "Rufnummer zur Portierung",

    "contract type": "Vertragsart",
    "DDI": "DDI",
    "ISDN": "ISDN (mehrere Nummern portieren)",
    "ANALOG": "Analog (einzelne Nummer portieren)",
    "transfer type": "Portierungsart",
    "FULL": "Vollportierung",
    "PARTIAL": "Teilportierung",
    "transfer priority": "Portierung Priorität",
    "NORMAL": "Vertragslaufzeit berücksichtigen",
    "FAST": "Vertragslaufzeit nicht berücksichtigen",
    "subscriber company": "Teilnehmer Firma",
    "subscriber name": "Teilnehmer Name",
    "subscriber address": "Teilnehmer Adresse",
    "your subscription": "Ihr Abonnement",
    "user identify data": "Daten zur Teilnehmer Identifizierung",
    "number transfer data": "Daten zur Rufnummer Portierung",
    "asap.": "schnellstmöglich",
    "notifyMsg": "Ihre Bestellung wurde erfolgreich übermittelt.",
    "POA document template": "Portierungvollmacht (POA) Vorlage",
    "end customer account data": "Endkundenkonto Daten",
    "customer account data": "Kundenkonto Daten",
    "is corporate": "ist Firma",
    "address": "Adresse",
    "lang_de": "Deutsch",
    "lang_en": "Englisch",
    "lang_fr": "Französisch",
    "lang_it": "Italienisch",
    "lang_es": "Spanisch",
    "lang_pt": "Portugiesisch",
    "sales partner options": "Vertriebspartner Optionen",
    "for my self": "für mich selbst",
    "on behalf of": "Im Auftrag von",
    "order on behalf of your customer": "Bestellung im Auftrag Ihres Kunden",
    "on behalf order type": "Bestelltyp",
    "onBehalfOrderTypeHint": "Das Produkt wird immer für Ihren Kunden mit Ihnen als Referenz angelegt. Für den Bestellablauf können Sie wählen, in wessen Namen die Bestellung und die Kommunikation ausgeführt wird.",
    "order happens through you": "Bestellung erfolgt über Sie",
    "order is placed by your customer": "Bestellung erfolgt über Ihren Kunden",
    "add customer": "eigenen Kunden hinzufügen",
    "change delivery address": "Lieferadresse anpassen",
    "preview delivery receipt": "Liefeschein Vorschau",
    "edit delivery receipt": "Lieferschein bearbeiten",
    "delivery": "Lieferung",
    "delivery address": "Lieferadresse",
    "delete delivery address": "Lösche Lieferadresse",
    "set default delivery address": "setze Standard Lieferadresse",
    "delivery receipt language": "Liefeschein Sprache",
    "delivery-receipt.pdf": "Lieferschein.pdf",
    "additional order data": "Zusätzliche Bestelldaten",
    "subscription price": "Abonnement Preis",
    "subscription setup fee": "Abonnement Installationsgebühr",
    "confirm subscription terms": "Abonnementbedingungen bestätigen",

    "new numbers": "Neue Rufnummern",
    "ported phone numbers": "Portierte Rufnummern",
    "minutes": "Minuten",
    "yes": "ja",
    "no": "nein",
    "analog connect type allows max. 1 number": "für Verbindungstyp Analog ist max. 1 Nummer erlaubt"
  }
}
</i18n>

<style scoped>
table > tbody > tr > td:nth-child(odd) {
  width: 40%;
}

table > tbody > tr > td:nth-child(even) {
  font-style: italic;
  width: 60%;
}
</style>
